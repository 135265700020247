import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import MktoForm from "../../components/mkto-form/mkto-form";
import Icon from "../../components/custom-widgets/icon";
import List from "../../components/custom-widgets/list";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import FAQAccordion from "../../components/faq/faq-accordion";

import showMktoForm from "../../helpers/showMktoForm";

const CommunityMultifamilyLending = () => {
  const title = "Multifamily, Self Storage & Mobile Home Park Loans";
  const description =
    "WaFd is a premier portfolio lender for multifamily, self-storage facilities, & manufactured home parks with over 100 years of loan experience. Contact us today.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/commercial-banking/multifamily/hero-multifamily-020123-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: "Multifamily, Self Storage & Mobile Home Park Loans",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/multifamily-self-storage-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-multifamily-020123.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "multifamily-hero",
    ...getHeroImgVariables(imgData),
    altText: "Modern multifamily buildings.",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Grow your real estate portfolio while investing in your community"
          }
        },
        {
          id: 2,
          button: {
            class: "btn-white",
            type: "anchor-link",
            text: "Contact Us",
            id: "hero-cta-btn",
            url: "#multifamily-lending-marketo-form",
            icon: {
              name: "arrow-down",
              lib: "far",
              class: "float-right mt-1 ml-0 ml-sm-1 ml-md-3"
            }
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Multifamily and Self Storage Lending"
    }
  ];

  const multifamilyList = {
    greenCircleCheckmark: true,
    idList: 1,
    items: [
      {
        id: 1,
        text: "5+ unit multifamily properties, self-storage facilities, and  manufactured home parks"
      },
      {
        id: 2,
        text: "Cash-out refinancing, interest only, and non-recourse options"
      },
      {
        id: 4,
        text: "Loan amounts from $250,000 to $2,000,000. Larger loan amounts  can be considered"
      },
      {
        id: 5,
        text: "Fixed and adjustable rate programs with 5, 7, 10, and 15 year options"
      },
      {
        id: 6,
        text: "Up to 30-year amortization"
      }
    ]
  };

  const mkto = useRef(null);

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://app-sj21.marketo.com/js/forms2/js/forms2.min.js";
    scriptTag.id = "mkto-script";

    if (typeof window !== "undefined") {
      if (!document.getElementById("mkto-script")) {
        mkto.current.appendChild(scriptTag);
        const checkInterval = setInterval(() => {
          // console.log("checking");
          if (typeof MktoForms2 !== "undefined") {
            // console.log("script loading...");
            MktoForms2.loadForm("//316-DXP-546.mktoweb.com", "316-DXP-546", 1325);
            clearInterval(checkInterval);
            // console.log("script loaded");
          } else {
            // console.log("no script yet");
          }
        }, 500);
      }
    }
  }, []);

  const faqAccordionData = {
    id: "multifamily-lending-faq-accordion",
    faqCategoryNames: ["Multifamily Lending"]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <MktoForm />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Financing for Multifamily, Self-Storage Facilities, and Manufactured Home Parks</h1>
        <div className="row d-flex">
          <div className="col-md">
            <h3 className="text-green-60">We are a portfolio lender with over 100 years of experience.</h3>
            <p className="gray-90">
              Our team of specialists will design a loan to fit your individual needs and stand by you through the life
              of your loan. You can count on responsive, knowledgeable answers to your questions and fast, efficient
              turnaround. Whether purchasing a new property or refinancing your current building, become another
              satisfied customer and contact us!
            </p>
          </div>
          <div className="col-md">
            <List {...multifamilyList} />
            <AnchorLink
              to="#multifamily-lending-faq-accordion"
              className="ml-2 text-decoration-none"
              gatsbyLinkProps={{ id: "multifamily-lending-faq-link" }}
            >
              <Icon name="question-circle" class="mr-1" /> See FAQ's for more information
            </AnchorLink>
          </div>
        </div>
        <div id="multifamily-lending-marketo-form">
          {/* Show entire Marketo form without having to click button - >= MD devices */}
          <div className="d-none d-md-block" id="multifamily-lending-marketo-form-md">
            <div className="d-flex justify-content-center" ref={mkto}>
              <div className="border border-radius-12 bg-light my-4">
                <script src="//app-sj21.marketo.com/js/forms2/js/forms2.min.js"></script>
                <form id="mktoForm_1325"></form>
                <script>MktoForms2.loadForm("//316-DXP-546.mktoweb.com", "316-DXP-546", 1325);</script>
              </div>
            </div>
          </div>
          {/* Show the Marketo form in a modal < MD */}
          <div className="d-md-none my-4" id="multifamily-lending-marketo-form-mobile">
            <button
              id="contact-community-multifamily-lending-button"
              className="btn btn-primary w-100 w-sm-auto"
              type="button"
              onClick={() => showMktoForm(1325)}
            >
              Contact Community Multifamily Lending
            </button>
          </div>
        </div>
        <p className="text-center text-xs" id="multifamily-disclosure">
          Loans are subject to credit approval and other underwriting criteria. Terms and conditions are subject to
          change without notice. Equal Housing Lender
        </p>

        <FAQAccordion {...faqAccordionData} />
      </section>
    </SecondaryLanding>
  );
};
export default CommunityMultifamilyLending;
